import React from 'react';
import './services.css';
class Services extends React.Component {
  render() {
    return (
      <section className='slide fade-6 kenBurns fromRight after' id='services-section'>
        <div className='content'>
          <div className='container'>
            <div className='wrap'>
              <h1><b>NOS SAVOIR-FAIRE</b></h1>
              <div className='service-content'>

                <div className='service-block'>
                  <div className='service ae-2 popupTrigger' data-popup-id='modal-service1'>
                    <img alt='Applications mobile' className='icon-services' src='assets/img/icons/iconRobot.png' />
                    <h2 className='service-title'><b>Applications mobiles</b></h2>
                    <h4 className='text-service'><p>Quelque soit la plateforme, soyez toujours dans la poche de vos utilisateurs</p></h4>
                  </div>

                  <div className='service ae-1 popupTrigger' data-popup-id='modal-service2'>
                    <img alt='Applications web' className='icon-services' src='assets/img/icons/icon1.png' />
                    <h2 className='service-title'><b>Applications web</b></h2>
                    <h4 className='text-service'><p>Du site vitrine  à l&lsquo;e-commerce, l&lsquo;ensemble des outils pour répondre à vos besoins métiers</p></h4>
                  </div>
                </div>

                <div className='service-block'>
                  <div className='service ae-3 popupTrigger' data-popup-id='modal-service3'>
                    <img alt='Applications web' className='icon-services' src='assets/img/icons/iconSatelite.png' />
                    <h2 className='service-title'><b>UX</b></h2>
                    <h4 className='text-service'><p>Maximiser l&lsquo;engagement de vos utilisateurs en offrant la meilleure des expériences</p></h4>
                  </div>

                  <div className='service ae-4 popupTrigger' data-popup-id='modal-service4'>
                    <img alt='Conseils' className='icon-services' src='assets/img/icons/iconGalaxy.png' />
                    <h2 className='service-title'><b>Conseils/Prestations</b></h2>
                    <h4 className='text-service'><p>Force de proposition et expertise technique pour être en adéquation avec vos contraintes</p></h4>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div className='background' style={{ backgroundImage: 'url(assets/img/background/land.jpg)' }} />

        <div className='popup popup-service' data-popup-id='modal-service1' onClick={() => window.$('#close-service1').click()}>
          <div className='background background-service-details' style={{ backgroundImage: 'url(assets/img/background/colorBackgMob.jpg)' }} />
          <div className='content'>
            <div className='container'>
              <div className='wrap'>

                <div className='popupContent service-details'>
                  <div>

                    <h1 className='title-service'>Applications</h1>
                    <h1 className='title-service'>Mobiles</h1>

                  </div>
                  <div className='text-service'>
                    <div className='text-service-content'>

                      <p>Aujourd&lsquo;hui les recherches internet de services ou de produits se font en majorité à l&lsquo;aide d&lsquo;un smartphone.
                        L&lsquo;internet des mobiles est devenu incontournable et s&lsquo;en passer est un frein à toute activité.

                      </p>
                      <p>
                        C&lsquo;est pourquoi, chez mintset, nous ne nous contentons pas de vous fournir une version réduite de votre site internet :
                        nous construisons des expériences utilisateurs sur mesure.
                      </p>
                      <p>
                        Une multitudes de technologies existe et il est souvent dificile de faire les meilleurs choix dans des contraintes de temps et de budget donnés.
                        C&lsquo;est ici que nous intervenons pour construire avec vous la solution optimale.
                        Cross-plateforme, PWA, application native... Un jargon qui peut paraitre obscur mais dont nous ferons un réel avantage pour votre buisness.
                      </p>

                      <a className='button button-change blue' href='#contact-section'>
                        Demander votre devis
                      </a>
                      <p className='close close-popup-change'>
                        retour
                      </p>
                    </div>
                    {/* <div className='text-service-overlay' /> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='popup popup-service' data-popup-id='modal-service2' onClick={() => window.$('#close-service2').click()}>
          <div className='background background-service-details-web' style={{ backgroundImage: 'url(assets/img/background/colorBackg.jpg)' }} />

          <div className='close' id='close-service2'> aaaaa </div>
          <div className='content'>
            <div className='container'>
              <div className='wrap'>

                <div className='popupContent service-details-web'>
                  <div>
                    <h1 className='title-service'>Applications</h1>
                    <h1 className='title-service'>Web</h1>
                  </div>
                  <div className='text-service'>
                    <div className='text-service-content'>

                      <p>
                        La construction d&#39;un site internet est un moment charnière  dans la vie d&#39;une entreprise.
                        Que ce soit pour être simplement visible, pour vendre vos produits ou bien la gestion de processus internes de votre stucture,
                        l&#39;étape numérique se révèle un atout majeur quand celle-ci est pensée avec la bonne expertise.
                        C&#39;est votre image dans le monde numérique que nous façonnerons avec vous et vos valeurs.
                      </p>
                      <p>
                        C&#39;est pourquoi nous existons. Notre métier est de vous comprendre avec vos besoins, vos atouts et vos attentes, pour construre ensemble votre succès.
                      </p>
                    </div>
                    <a className='button button-change blue' href='#contact-section'>
                      Demander votre devis
                    </a>
                    <p className='close close-popup-change'>
                      retour
                    </p>

                    {/* <div className='text-service-overlay' /> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='popup popup-service background-service-details-ux' data-popup-id='modal-service3' onClick={() => window.$('#close-service3').click()}>
          <div className='background background-service-details-web' style={{ backgroundImage: 'url(assets/img/background/colorBackTab.jpg)' }} />

          <div className='close' id='close-service3'> aaaaa </div>
          <div className='content'>
            <div className='container'>
              <div className='wrap'>
                <div>
                  <div className='popupContent ux-popup-content'>
                    <div>
                      <h1 className='title-service'>Expérience</h1>
                      <h1 className='title-service'>Utilisateur</h1>
                    </div>
                    <p>
                      Vos utilisateurs ou vos clients ont des attentes. La patience n&apos;est pas une de leurs caractéristiques principales.
                      La multiplicité des choix pousse les usagers d&apos;une application vers un autre fournisseur de services ou de produits si leurs expériences d&apos;un site web n&apos;est pas agréable et fluide.
                      Tout ceci est mesuré à l&apos;aide d&apos;outils qui montrent la volatilité d&apos;un utilisateur sur le web.
                    </p>
                    <p>
                      Construire des interfaces optimisées dans ce but est indispensable, voilà pourquoi nous en faisons une de nos préocupations premières.
                      Par notre expertise en sciences cognitives et nos expériences dans le développement informatique, nous amenons vos clients vers plus de fidélité et
                      de satisfaction en proposant des expériences utilisateurs toujours adaptées et optimales.
                    </p>
                    <a className='button  button-change button-change-consulting blue' href='#contact-section'>
                      Contactez nous
                    </a>
                    <p className='close close-popup-change'>
                      retour
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='popup popup-service' data-popup-id='modal-service4' onClick={() => window.$('#close-service4').click()}>
          <div className='background background-service-details-consulting' style={{ backgroundImage: 'url(assets/img/background/imageConseil.jpg)' }} />
          <div className='close' id='close-service4'> aaaaa </div>
          <div className='content'>
            <div className='container'>
              <div className='wrap'>
                <div className='fix-10-12'>
                  <div className='popupContent consulting-popup-content'>
                    <div>
                      <h2 className='title-service'>Prestation</h2>
                      <h2 className='title-service'>Conseil</h2>
                    </div>
                    <p>
                      Nos ingénieurs interviennent également auprès de vous en prestation ou en conseil. MINTSET est un vivier de talents à votre service. De NodeJS en passant par JEE et les technologies fronts Angular et React,
                      vous trouverez forcément chez nous le fruit dont vous avez besoin.
                    </p>
                    <a className='button  button-change button-change-consulting blue' href='#contact-section'>
                      Contactez nous
                    </a>
                    <p className='close close-popup-change'>
                      retour
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
export default Services;
