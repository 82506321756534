import React from 'react';
import './footer.scss';
class Footer extends React.Component {
  render() {
    const imageUrl = window.innerWidth >= 650 ? 'rail.jpg' : 'rail.jpg';
    return (

      <section className='slide fade-6 kenBurns fromRight after'>
        <div className='background' style={{ backgroundImage: 'url(assets/img/background/' + imageUrl + ')' }} />
        <div className='content'>
          <div className='container'>
            <div className='wrap'>
              <div className='footer-container'>
                <div className='footer-section'>
                  <h2>
                    Propulser votre entreprise
                  </h2>
                </div>


                <div className='footer-bottom'>
                  {/* <div className='footer-separator' /> */}
                  <img className='footer-logo' alt='logo' src='/assets/img/logos/MintsetLogoPourFondNoirFormatCarré.png' />

                  <span className='footer-legal popupTrigger' data-popup-id='popup-mention-leg'>Mentions légales</span>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div className='popup' data-popup-id='popup-mention-leg'>
          <div className='close'> aaaaa </div>
          <div className='content'>
            <div className='container'>
              <div className='wrap'>
                <div className='fix-10-12'>
                  <div className='popupContent'>
                    <p>
                      Ce site internet est édité par MINTSET, société par actions simplifiée au capital social de 1000&nbsp;euros, immatriculée au Registre du Commerce et des Sociétés
                      de Paris sous le numéro 880&nbsp;132&nbsp;626 et dont le siège social est situé 3 rue du Sénagal, 75020&nbsp;Paris.
                    </p>

                    <div className='footer-dotsContainer'>
                      <div className='footer-dots' />
                    </div>
                    <div>
                      <p>
                        3 rue du Sénégal
                      </p>
                      <p>
                        75020 Paris, France
                      </p>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
export default Footer;
