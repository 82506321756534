import './masterForm.css';

import React from 'react';
import axios from 'axios';
class MasterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      email: '',
      username: '',
      message: '',
      response: '',
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    console.log({ name, value });

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = event => {
    event.stopPropagation();
    event.preventDefault();
    window.$('#popupForm').click();

    const { email, username, message } = this.state;
    axios.post('/.netlify/functions/manageContact', {
      data: {
        name: username,
        email: email,
        message: message,
        type: 'mail',
      },
    }).then((response) => {
      this.setState({
        response: response.data,
      });
    });
  }

  render() {
    return (
      <div className='stepper kenBurns fromRight after'>

        <h1>NOUS CONTACTER</h1>

        <form className='form-stepper slides-form' onSubmit={this.handleSubmit}>
          <div className='step-inputs'>
            <Step1
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              email={this.state.email}
            />
            <Step2
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              username={this.state.username}
            />
          </div>
          <div className='stepper-button'>
            <button
              type='submit'
              className='button blue borderRadius button-send'
              name='button'
            >
              Envoyer
            </button>
            <button
              id='popupForm'
              data-popup-id='popup-contact-info'
              className='popupTrigger popupForm'
            />
          </div>
        </form>

        <div className='popup' data-popup-id='popup-contact-info'>
          <div className='close'> aaaaa </div>
          <div className='content'>
            <div className='container'>
              <div className='wrap'>
                <div className='fix-10-12'>
                  <div className='popupContent'>
                    <p><span> {this.state.response}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function Step1(props) {
  return (
    <>
      <label htmlFor='name'>Votre nom</label>
      <input
        required
        type='name'
        className='input-1 fromCenter borderRadius'
        id='username'
        name='username'
        placeholder='Nom'
        value={props.name}
        onChange={props.handleChange}
      />

      <label htmlFor='username'>Votre adresse mail</label>
      <input
        required
        className='input-1 fromCenter borderRadius'
        id='email'
        name='email'
        type='email'
        placeholder='Email'
        value={props.email}
        onChange={props.handleChange}
      />
    </>
  );
}

function Step2(props) {
  return (
    <>
      <label htmlFor='message'>Votre message</label>
      <textarea
        required
        className='input-2 fromCenter borderRadius'
        id='message'
        name='message'
        placeholder='Message'
        value={props.message}
        onChange={props.handleChange}
      />
    </>
  );
}

export default MasterForm;

