import React from 'react';
import './about.css';
class About extends React.Component {
  render() {
    const imageUrl = window.innerWidth >= 650 ? 'be1l.jpg' : 'bee.jpg';

    return (

      <section className='slide fade-6 kenBurns fromRight after' id='about-section'>
        <div className='content'>
          <div className='container'>
            <div className='wrap'>
              <p className='ae-3'><span className='opacity-6' /></p>
              <h1>
                L&apos;ÉQUIPE
              </h1>
              <section className='grid'>
                <div className='card-team ae-0'>
                  <div className='item'>
                    <div className='image'>
                      <img alt='profil de Julien' src='assets/img/team/teamJ.jpg' />
                    </div>
                  </div>
                  <div className='text-card-team ae-1'>
                    <figcaption>
                      <h4>Julien Faucher</h4>
                      <div className='div-icons-profil'>
                        <a target='_blank' rel=' noopener noreferrer' href='https://www.linkedin.com/company/mintset/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#linkedin' /></svg></a>
                        <a target='_blank' rel=' noopener noreferrer' href='https://www.facebook.com/Mintset-103222741231109/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#facebook2' /></svg></a>
                      </div>
                    </figcaption>
                  </div>
                </div>
                <div className='card-team ae-2'>
                  <div className='item'>
                    <div className='image'>
                      <img alt='profil de Erik' src='assets/img/team/teamE.jpg' />
                    </div>
                  </div>
                  <div className='text-card-team ae-3'>
                    <figcaption>
                      <h4>Erik Aouizerate</h4>
                      <div className='div-icons-profil'>
                        <a target='_blank' rel=' noreferrer noopener' href='https://www.linkedin.com/company/mintset/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#linkedin' /></svg></a>
                        <a target='_blank' rel=' noopener noreferrer' href='https://www.facebook.com/Mintset-103222741231109/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#facebook2' /></svg></a>
                        <a target='_blank' rel=' noreferrer noopener' href='https://twitter.com/0x_erik' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#twitter' /></svg></a>
                      </div>
                    </figcaption>
                  </div>
                </div>
                <div className='card-team ae-4'>
                  <div className='item'>
                    <div className='image'>
                      <img alt='profil de Alexandre' src='assets/img/team/teamA.jpg' />
                    </div>
                  </div>
                  <div className='text-card-team'>
                    <figcaption>
                      <h4>Alexandre Rose-Pizant</h4>
                      <div className='div-icons-profil'>
                        <a target='_blank' rel=' noreferrer noopener' href='https://www.linkedin.com/company/mintset/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#linkedin' /></svg></a>
                        <a target='_blank' rel=' noopener noreferrer' href='https://www.facebook.com/Mintset-103222741231109/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#facebook2' /></svg></a>
                      </div>
                    </figcaption>
                  </div>
                </div>
                <div className='card-team ae-5'>
                  <div className='item'>
                    <div className='image'>
                      <img alt='profil de Mohamed' src='assets/img/team/teamM2.jpg' />
                    </div>
                  </div>
                  <div className='text-card-team ae-6'>
                    <figcaption>
                      <h4>Mohamed Naji</h4>
                      <div className='div-icons-profil'>
                        <a target='_blank' rel=' noreferrer noopener' href='https://www.linkedin.com/company/mintset/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#linkedin' /></svg></a>
                        <a target='_blank' rel=' noopener noreferrer' href='https://www.facebook.com/Mintset-103222741231109/' className='icon-container'><svg width='20px' height='20px' viewBox='0 0 20 20'><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#facebook2' /></svg></a>
                      </div>
                    </figcaption>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className='background' style={{ backgroundImage: 'url(assets/img/background/' + imageUrl + ')' }} />
      </section>
    );
  }
};
export default About;
