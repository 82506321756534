import React from 'react';
import './inspiring-page.css';
class InspiringPage extends React.Component {
  render() {
    const imageUrl = window.innerWidth >= 650 ? 'dna.jpg' : 'dnaV.jpg';

    return (
      <section className='slide fade-6 kenBurns fromRight after' id='presentation-section'>
        <div className='content content-change'>
          <div className='container'>
            <div className='wrap'>
              <div className='fix-12-12'>
                <div className='fix-6-12 left toLeft'>
                  <h1 className='ae-1'>NOTRE ADN</h1>
                  <p className='ae-2'>
                    {/* <span className='opacity-8'>
                      Mintset, de l'expertise à l'experience.


                      L'expérience, la votre et celle de vos utilisateurs.
                      L'expertise, la notre et la rencontre de nos metiers.
                      -------------
                      - relation client
                      - expertise
                      -expériance
                      - conseil
                      -l'esprit d'ensemble
                      - mintset
                      - l'homme et la machine et nous au milieu
                      - l'utilisateur
                      --------------
                      L'experience utilisateurs, autant pour vous que
                      Nous travaillons avec des machines et nous le faisons pour vous.

                      L’homme est prépondérant à la machine car c’est la raison même du succès d’un produit. C’est pour cela que nous plaçons l’humain au coeur de nos démarches.
                    </span> */}
                  </p>

                  <p className='ae-2'>
                    <span className='opacity-8'>
                      Nos mettons notre expertise et notre expérience au service de nos clients.
                      MINTSET est né de la volonté d&apos;optimiser l&apos;expertise informatique et la relation client, toutes deux au service
                      de votre succès. Vous trouverez auprès de nos ingénieurs la connaissance technique pour maximiser la réussite
                      de vos projets mais également de vrais interlocuteurs, à l&apos;écoute et disponibles.
                    </span>
                  </p>
                  <p className='ae-2'>
                    <span className='opacity-8'>
                      L&apos;expertise, le relationnel, la sincérité, la confiance forment l&apos;ADN de MINTSET.
                    </span>
                  </p>
                  {/* <button className="button green gradient ae-7 fromCenter opacity-8" name="button">Nous contacter</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='background' style={{ backgroundImage: 'url(assets/img/background/' + imageUrl + ')' }} />
      </section>
    );
  }
};
export default InspiringPage;
