import React from 'react';
import './App.css';
import Landing from './components/landing.js';
import Navigation from './components/navigation/navigation.js';
import InspiringPage from './components/inspiring-page/inspiring-page';
import Contact from './components/contact/contact';
import Services from './components/services/services';

import Footer from './components/footer/footer';
import About from './components/about/about';
import FindUs from './components/find-us/find-us';

function App() {
  return (
    <div className='App'>
      <Navigation />
      <Landing />
      <InspiringPage />
      <Services />
      <About />
      <Contact />
      <FindUs />
      <Footer />
      <a className='button yellow actionButton' id='contact-button' href='#contact-section'>
        <svg>
          <use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#mail' />
        </svg>
      </a>
    </div>
  );
}

export default App;
