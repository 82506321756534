import React from 'react';
import './landing.css';
import Particles from 'react-particles-js';
import axios from 'axios';
class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      number: '',
      response: '',
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = event => {
    event.stopPropagation();
    event.preventDefault();
    const { username, number } = this.state;
    window.$('#popupLanding').click();
    axios.post('/.netlify/functions/manageContact', {

      data: {
        username: username,
        number: number,
        type: 'rappelle',
      },
    }).then((response) => {
      this.setState({
        response: response.data,
      });
    });
  }

  render() {
    const imageUrl = window.innerWidth >= 650 ? 'leaf-1209927_1920.jpg' : 'veineV1.jpg';

    return (
      <section className='slide fade-6 kenBurns ' id='home-section'>
        <div className='background' style={{ backgroundImage: 'url(assets/img/background/' + imageUrl + ')', zIndex: 0 }}>
          <div id='particles-js' style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, zIndex: 101 }} />
        </div>
        <div className='content'>
          <div className='container'>
            <div className='wrap'>
              <Particles
                style={{ zIndex: 0, position: 'absolute', width: '100vw', height: '100vh', top: 0, bottom: 0, left: 0 }}
                params={{
                  particles: {
                    number: {
                      value: 40,
                      density: {
                        enable: true,
                        value_area: 800,
                      },
                    },
                    color: {
                      value: '#ffffff',
                    },
                    shape: {
                      type: 'circle',
                      stroke: {
                        width: 0,
                        color: '#000000',
                      },
                      polygon: {
                        nb_sides: 8,
                      },
                    },
                    opacity: {
                      value: 0.9,
                      random: false,
                      anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false,
                      },
                    },
                    size: {
                      value: 3,
                      random: true,
                      anim: {
                        enable: false,
                        speed: 40,
                        size_min: 0.1,
                        sync: false,
                      },
                    },
                    line_linked: {
                      enable: true,
                      distance: 150,
                      color: '#ffffff',
                      opacity: 0.4,
                      width: 1,
                    },
                    move: {
                      enable: true,
                      speed: 3,
                      direction: 'random',
                      random: true,
                      straight: false,
                      out_mode: 'out',
                      bounce: false,
                      attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                      },
                    },
                  },
                  interactivity: {
                    detect_on: 'canvas',
                    events: {
                      onhover: {
                        enable: true,
                        mode: 'grab',
                      },
                      onclick: {
                        enable: true,
                        mode: 'push',
                      },
                      resize: true,
                    },
                    modes: {
                      grab: {
                        distance: 300,
                        line_linked: {
                          opacity: 1,
                        },
                      },
                      bubble: {
                        distance: 400,
                        size: 40,
                        duration: 2,
                        opacity: 8,
                        speed: 3,
                      },
                      repulse: {
                        distance: 200,
                        duration: 0.4,
                      },
                      push: {
                        particles_nb: 4,
                      },
                      remove: {
                        particles_nb: 2,
                      },
                    },
                  },
                  retina_detect: true,
                }}
              />
              <div className='fix-12-12' style={{ zIndex: 1 }}>
                <div className='ae-3'><span className='opacity-6' /><h1>Solutions <b>web</b> et <b>mobiles</b></h1></div>
                <p className='ae-5'>Demandez-nous ce que le numérique peut vous apporter</p>
                <form className='slides-form opacity-8 landing-form' autoComplete='off' onSubmit={this.handleSubmit}>
                  <input
                    type='name'
                    onChange={this.handleChange}
                    value={this.props.username}
                    className='input-2 ae-6 fromCenter borderRadius'
                    name='username'
                    placeholder='Nom'
                    required
                  />
                  <input
                    required
                    type='tel'
                    onChange={this.handleChange}
                    value={this.props.number}
                    className='input-8 ae-6 fromCenter borderRadius'
                    name='number'
                    placeholder='Numéro de téléphone'
                  />
                  <button
                    type='submit'
                    className='landing-button button blue gradient ae-6 fromCenter borderRadius'
                    name='button'
                  >
                    <button data-popup-id='popup-info' id='popupLanding' className='popupTrigger popupLanding' />
                    <b>Rappelez-moi</b>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='popup' data-popup-id='popup-info'>
          <div className='close'> x </div>
          <div className='content'>
            <div className='container'>
              <div className='wrap'>
                <div className='fix-10-12'>
                  <div className='popupContent'>
                    <p><span> {this.state.response}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Landing;
