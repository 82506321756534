import React, { createRef } from 'react';
import './find-us.scss';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
class FindUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 46.227638,
      lng: 2.213749,
      zoom: 5,
      country: 'test',
      party: 'cool',
    };
    this.mapRef = createRef();
    this.groupRef = createRef();
    this.places = [{
      lat: 48.866667,
      lng: 2.333333,
      name: 'Paris',
    }, {
      lat: 45.75,
      lng: 4.85,
      name: 'Lyon',
    }, {
      lat: 45.166667,
      lng: 5.716667,
      name: 'Grenoble',
    }, {
      lat: 44.933393,
      lng: 4.89236,
      name: 'Valence',
    }];
    this.greenIcon = L.icon({
      iconUrl: 'assets/img/background/leaf-mint.png',
      iconSize: [50, 100], // size of the icon
      shadowSize: [20, 20], // size of the shadow
      iconAnchor: [25, 100], // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62], // the same for the shadow
      popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
    });
  }

  updateLocation = (position) => {
    const map = this.mapRef.current.leafletElement;
    map.on('locationfound', function(ev) {
    });
    this.setState(() => {
      return {
        lat: position.lat,
        lng: position.lng,
        zoom: 7,

      };
    });
  };


  createMarker(position, name) {
    const where = [position.lat, position.lng];
    return (
      <Marker position={where} key={name} icon={this.greenIcon}>
        <Popup>
          <span>{name}</span>
        </Popup>
      </Marker>
    );
  }

  createAllMarkers() {
    const markers = [];
    for (var i = 0; i < this.places.length; i++) {
      markers.push(this.createMarker(this.places[i], this.places[i].name));
    }
    // var group = new L.featureGroup([this.places]);
    // Map.fitBounds(leafletMap.getBounds());

    return markers;
  }


  render() {
    const position = [this.state.lat, this.state.lng];

    return (
      <section className='slide fade-6 kenBurns fromRight after' id='find-section'>
        <div className='content'>
          <div className='container'>
            <div className='wrap'>
              <div>
                <div>
                  <h1>OÙ NOUS TROUVER</h1>
                  <h4>
                    <p>MINTSET est basé en région Parisienne et Rhône-Alpes pour être au plus proche de vous </p>
                  </h4>
                  {/* <ul className='city-list'>
                    <li className='city-find'><a  onMouseMove={() => this.updateLocation(this.places[0])}><h3>{this.places[0].name}</h3></a></li>
                    <li className='city-find'><a   onMouseMove={() => this.updateLocation(this.places[1])}><h3>{this.places[1].name}</h3></a></li>
                  </ul> */}
                </div>

                <Map center={position} zoom={this.state.zoom} ref={this.mapRef} zoomControl={false} maxZoom='12'>

                  <div className='city-map left-city1' onMouseMove={() => this.updateLocation(this.places[0])}><h3>Paris  </h3>  </div>
                  <div className='city-map left-city2' onMouseMove={() => this.updateLocation(this.places[1])}><h3>Lyon   </h3> </div>
                  <div className='city-map right-city1' onMouseMove={() => this.updateLocation(this.places[2])}><h3>Grenoble   </h3> </div>
                  <div className='city-map right-city2' onMouseMove={() => this.updateLocation(this.places[3])}><h3>Valence    </h3></div>

                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://api.mapbox.com/styles/v1/ceec/ciyxop55w000h2qo9pqyfyy9w/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2VlYyIsImEiOiJjaXl1bWlwZ2QwMW1sMzNxbjMxN3JhdGJlIn0.Y_U5JsNv727e2TqKTJ7gTQ'
                  />
                  <MarkerClusterGroup ref={this.groupRef}>         {this.createAllMarkers()}</MarkerClusterGroup>
                </Map>

                {/* <ul className='city-list'>
                  <li className='city-find'><a href='#find-section' onMouseMove={() => this.updateLocation(this.places[2])}><h3>{this.places[2].name}</h3></a></li>
                  <li className='city-find'><a href='#find-section' onMouseMove={() => this.updateLocation(this.places[3])}><h3>{this.places[3].name}</h3></a></li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default FindUs;
