import React from 'react';
import './contact.css';
import MasterForm from '../wiz-form/masterForm';
class Contact extends React.Component {
  render() {
    return (


      <section className='slide fade-6 kenBurns fromRight after' id='contact-section'>
        <div className='content'>
          <div className='container'>
            <div className='wrap'>
              <div className='fix-12-12'>

                <MasterForm />
              </div>
            </div>
          </div>
        </div>

        <div className='background' style={{ backgroundImage: 'url(assets/img/background/messageV1.jpg)' }} />
      </section>


    );
  }
};
export default Contact;
