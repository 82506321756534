import React from 'react';
import './navigation.scss';
class Navigation extends React.Component {
  render() {
    return (
      <div>
        {/* Navigation */}
        <nav className='side pole side-change'>
          <div className='navigation side-change'>
            <ul />
          </div>
        </nav>
        {/* Panel Top #08 */}
        <nav className='panel top panel-top-change'>

          <div className='sections desktop'>
            <div className='left'>
              <img src='/assets/img/logos/MintsetLogoPourFondNoir.png' className='App-logo' style={{ width: '300px', opacity: '1' }} alt='logo' />
            </div>
            <div className='center'>
              <ul className='menu uppercase'>
                <li><a href='#home-section'>Accueil</a></li>
                <li><a href='#presentation-section'>Notre ADN</a></li>
                <li><a href='#services-section'>Nos Savoir-faire</a></li>
                <li><a href='#about-section'>L&apos;équipe</a></li>
                <li><a href='#contact-section'>Contact</a></li>
              </ul>
            </div>
          </div>
          <div className='sections compact hidden'>

            <div> <img src='/assets/img/logos/MintsetLogoPourFondNoir.png' className='App-logo' style={{ width: '256px', opacity: '1' }} alt='logo' /></div>

            <div className='right'><span className='button actionButton sidebarTrigger' data-sidebar-id={1}><svg><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#menu' /></svg></span></div>
          </div>
        </nav>
        {/* Sidebar */}
        <nav className='sidebar' data-sidebar-id={1}>
          <div className='close'><svg><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#close' /></svg></div>
          <div className='content'>
            <div> <img src='/assets/img/logos/MintsetLogoPourFondNoir.png' className='App-logo' style={{ width: '128px', opacity: '1' }} alt='logo' /></div>

            {/* <a href="#" className="logo"><svg width={37} height={30}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#logo-icon" /></svg></a> */}
            <ul className='mainMenu margin-top-3'>
              <li><a href='#home-section'>ACCUEIL</a></li>
              <li><a href='#presentation-section'>NOTRE ADN</a></li>
              <li><a href='#services-section'>NOS SAVOIR-FAIRE</a></li>
              <li><a href='#about-section'>L&apos;ÉQUIPE</a></li>
              <li><a href='#contact-section'>CONTACT</a></li>
            </ul>
            {/* <ul className='social opacity-8'>
              <li><a href='#'><svg><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#facebook' /></svg></a></li>
              <li><a href='#'><svg><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#twitter' /></svg></a></li>
              <li><a href='#'><svg><use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref='#instagram' /></svg></a></li>
            </ul> */}
          </div>
        </nav>
      </div>
    );
  }
};
export default Navigation;
